import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import setAuthToken from "../util/setAuthToken";
import jwt_decode from "jwt-decode";
import logo from "assets/logo.jpg";

import { loginUser } from "../appRedux/actions/AuthActions";

const FormItem = Form.Item;

class SignIn extends React.Component {
  state = { errors: {}, disabled: false };
  componentDidMount() {
    console.log("is auth");
    console.log(this.props.auth);
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.role == "admin") {
        this.props.history.push("/admin/dashboard");
      } else if (
        this.props.auth.user.role == "doctor" ||
        this.props.auth.user.role == "doctorNurse"
      ) {
        //   console.log(this.props.auth.user)
        this.props.history.push("/doctor/verification");
      } else if (
        this.props.auth.user.role == "practice" ||
        this.props.auth.user.role == "practiceNurse"
      ) {
        this.props.history.push("/practice/verification");
      } else {
        this.props.history.push("/sale/dashboard");
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.auth.isAuthenticated) {
      if (nextProps.auth.user["role"] == "admin") {
        this.props.history.push("/admin/dashboard"); // push user to dashboard when they login
      } else if (
        nextProps.auth.user["role"] == "doctor" ||
        nextProps.auth.user["role"] == "doctorNurse"
      ) {
        //   console.log(this.props.auth.user)
        this.props.history.push("/doctor/verification");
      } else if (
        nextProps.auth.user["role"] == "practice" ||
        nextProps.auth.user["role"] == "practiceNurse"
      ) {
        this.props.history.push("/practice/verification");
      } else {
        this.props.history.push("/sale/dashboard"); // push user to dashboard when they login
      }
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }
  handleSubmit = (e) => {
    this.setState({ disabled: true });
    const last2FAAuth = localStorage.getItem("last2FAAuth");
    let is2FARequired = false;

    if (last2FAAuth) {
      const lastAuthDate = new Date(last2FAAuth);
      const currentDate = new Date();
      const diffDays = (currentDate - lastAuthDate) / (1000 * 60 * 60 * 24);
      is2FARequired = diffDays > 14;
    } else {
      is2FARequired = true;
    }

    this.props.loginUser(e, this.props.history, is2FARequired); // Pass history here
  };

  handleChange = (e) => {
    if (this.state.disabled) {
      this.setState({ disabled: false });
    }
  };

  render() {
    return (
      <div className="gx-app-login-wrap" style={{ marginTop: "15%" }}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {window.location.hostname == "portal.femmegen.health" && (
                  <img
                    src={
                      "https://s3.us-east-2.wasabisys.com/medshipohio/Static/FemmGen-Yellow-Wings.png"
                    }
                    alt="Neature"
                  />
                )}
                {window.location.hostname == "portal.powderm.health" && (
                  <img
                    src={
                      "https://s3.us-east-2.wasabisys.com/medshipohio/Static/powderm.jpg"
                    }
                    alt="Neature"
                  />
                )}
                {window.location.hostname == "portal.medship.health" && (
                  <img src={logo} alt="Neature" />
                )}
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>

            <div className="gx-app-login-content">
              <Form
                onFinish={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem
                  name="email"
                  onChange={this.handleChange}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input name="email" placeholder="Email" />
                </FormItem>
                <FormItem
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                  />
                </FormItem>
                <Link
                  to="/forgot"
                  style={{ marginTop: 20, marginBottom: 20, padding: 10 }}
                >
                  <span>Forgot Your Password ?</span>
                </Link>
                <FormItem>
                  <Button
                    type="primary"
                    style={{ marginTop: 20, marginBottom: 20 }}
                    className="gx-mb-0"
                    htmlType="submit"
                    disabled={this.state.disabled}
                  >
                    signIn
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authR,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  loginUser,
})(SignIn);
