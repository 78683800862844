import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Typography, Form, Radio, Button } from "antd";
import { logoutUser } from "../../../appRedux/actions/AuthActions";
import { Link } from "react-router-dom";

import axios from "axios";
import config from "../../../config";
import { NumericFormat } from "react-number-format";
import { InputOTP } from "antd-input-otp";

import SimpleAlertModel from "../../../components/Modals/SimpleAlertModal";

const UserProfile = (props) => {
  const [isOpen2FaModel, setIsOpen2FaModel] = useState(false);
  const [isOpenVerify2FAModel, setIsOpenVerify2FAModel] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [isOpenVerifyMobile2FAModel, setisOpenVerifyMobile2FAModel] =
    useState(false);

  useEffect(() => {
    // setIsOpen2FaModel(!props?.auth?.authR?.user?.isTwoFactorEnabled);
  }, [props]);

  const onShowModel = () => {
    setIsOpen2FaModel(true);
  };

  const onVerifyTwoFA = (values) => {
    if (selectedOption === "Email") {
      setLoading(true);

      let data = {
        otp: values.otp.join(""),
        email: props?.auth?.authR?.user?.email,
        twoFAMethod: "Email",
      };
      axios
        .post(`${config.API_URL}/auth/verify-2fa`, {
          values: data,
        })
        .then((response) => {
          setLoading(true);

          if (response.data.success) {
            message.success("Your Email has been verified");
            props.logoutUser();
          }
        })
        .catch((error) => {
          message.error(response.data.message);
          setLoading(false);

          if (response.data.isLogout) {
            props.logoutUser();
          }
        });
    } else {
      setPhone(values.phone);
      setLoading(true);

      let data = {
        email: values.phone,
      };

      axios
        .post(`${config.API_URL}/auth/send-otp-mobile`, {
          values: data,
        })
        .then((response) => {
          if (response?.data?.success) {
            setIsOpenVerify2FAModel(false);
            setisOpenVerifyMobile2FAModel(true);
          }
          setLoading(false);
        });
    }
  };
  const on2FaVerify = (values) => {
    setLoading(true);
    let data = {
      otp: values.otp.join(""),
      email: phone,
      twoFAMethod: "Phone",
    };
    axios
      .post(`${config.API_URL}/auth/verify-2fa`, {
        values: data,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          message.success("Your Phone has been verified");
          props.logoutUser();
        }
      })
      .catch((error) => {
        message.error(response.data.message);
        setLoading(false);

        if (response.data.isLogout) {
          logoutUser();
        }
      });
  };

  const onSelect2FaMethod = (values) => {
    setSelectedOption(values.Select2FaMethod);
    if (values.Select2FaMethod === "Email") {
      let data = {
        email: props?.auth?.authR?.user?.email,
      };

      axios
        .post(`${config.API_URL}/auth/send-otp`, {
          values: data,
        })
        .then((response) => {
          if (response?.data?.success) {
            setIsOpenVerify2FAModel(true);
            setIsOpen2FaModel(false);
          }
        });
    } else {
      setIsOpenVerify2FAModel(true);
      setIsOpen2FaModel(false);
    }
  };

  const userMenuOptions = () => (
    <ul className="gx-user-popover">
      <li>
        <Link style={{ color: "black" }} to="/admin/config">
          Config
        </Link>
      </li>

      <li onClick={() => props.logoutUser()}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <div className="spn">Logged In As</div>
        <span className="spn gx-avatar-name">
          {props.auth && props.auth.isAuthenticated
            ? props.auth.user.name
            : "Admin"}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
      {console.log(isOpen2FaModel)}
      <SimpleAlertModel
        isModalOpen={isOpen2FaModel}
        title="Two Factor Authentication Is Required"
      >
        <Typography style={{ marginBottom: "1rem" }}>
          MedShip requires all accounts to be secured with two-factor
          authentication. Please choose which authentication method you would
          like to use
        </Typography>
        <Form
          name="radio_form"
          onFinish={onSelect2FaMethod}
          initialValues={{ Select2FaMethod: "Email" }}
        >
          <Form.Item
            name="Select2FaMethod"
            label="Select an Option"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Radio.Group>
              <Radio value="Email">Email</Radio>
              <Radio value="Phone">Phone</Radio>
            </Radio.Group>
          </Form.Item>
          <hr></hr>
          <Form.Item
            style={{
              marginBottom: "0px",
              textAlign: "right",
              marginRight: "2rem",
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </SimpleAlertModel>
      <SimpleAlertModel
        isModalOpen={isOpenVerify2FAModel}
        title="Verify Email Model"
      >
        <Form
          name="otp-From"
          onFinish={onVerifyTwoFA}
          style={{
            maxWidth: 600,
          }}
        >
          {selectedOption === "Email" ? (
            <>
              <Typography>
                Please check your email for the 6 digit authentication code and
                enter it below.
              </Typography>
              <Form.Item
                style={{ margin: "1rem 2rem" }}
                rules={[{ required: true, message: "Please input your otp" }]}
                name="otp"
              >
                <InputOTP size="small" length={6} inputType="numeric" />
              </Form.Item>

              <hr />
              <Form.Item
                style={{
                  marginBottom: "0px",
                  textAlign: "right",
                  marginRight: "2rem",
                }}
              >
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <Typography>
                Please enter your 10 digit US based phone number capable of
                receiving SMS text messages.
              </Typography>
              <Form.Item
                label="Phone Number"
                style={{ margin: "1rem 2rem" }}
                rules={[{ required: true, message: "Please input your Phone" }]}
                name="phone"
              >
                <NumericFormat
                  style={{
                    border: "1px solid #D4B4CF",
                    borderRadius: "4px",
                    width: "100%",
                    padding: "8px 8px",
                    outline: "none",
                    background: "linear-gradient(top, #f9f9f9, #e3e3e3)",
                  }}
                  format="(###) ###-####"
                  placeholder="Please Enter Number"
                  name="phone"
                />
              </Form.Item>

              <hr />
              <Form.Item
                style={{
                  marginBottom: "0px",
                  textAlign: "right",
                  marginRight: "2rem",
                }}
              >
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </SimpleAlertModel>
      <SimpleAlertModel
        isModalOpen={isOpenVerifyMobile2FAModel}
        title="Verify 2FA Mobile Model"
      >
        <Typography>
          Please check your phone ######{phone?.slice(-4)} for your 6 digit
          authentication code and enter it below.
        </Typography>
        <Form
          name="otp-From"
          onFinish={on2FaVerify}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            style={{ margin: "1rem 2rem" }}
            rules={[{ required: true, message: "Please input your otp" }]}
            name="otp"
          >
            <InputOTP size="small" length={6} inputType="numeric" />
          </Form.Item>

          <hr />
          <Form.Item
            style={{
              marginBottom: "0px",
              textAlign: "right",
              marginRight: "2rem",
            }}
          >
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginBottom: "0px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </SimpleAlertModel>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.authR,
});

//export default UserProfile
export default connect(mapStateToProps, { logoutUser })(UserProfile);
