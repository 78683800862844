import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePrescription,
  faFileInvoiceDollar,
  faReceipt,
  faHandHoldingDollar,
  faUserDoctor,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import config from "../../../config";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  state = {
    nurse: "",
  };

  getAdmin = () => {
    const token = localStorage.getItem("token");
    var endpoint =
      this.props.authR?.user?.role == "doctorNurse"
        ? "get/current/nurse"
        : "get/current/doctor";
    axios.get(`${config.API_URL}/doctor/${endpoint}`, {}).then((response) => {
      this.setState({
        nurse: response.data.nurse,
      });
    });
  };

  componentDidMount() {
    this.getAdmin();
  }
  render() {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={"gx-sidebar-notifications "}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              className="mnu"
              mode="inline"
            >
              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes("Dashboard"))) && (
                <Menu.Item key="dashboard">
                  <Link to="/doctor/dashboard">
                    <i className="icon icon-widgets" />
                    <span className="spn">Dashboard</span>
                  </Link>
                </Menu.Item>
              )}
              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes("Orders"))) && (
                <Menu.Item key="orders">
                  <Link to="/doctor/orders">
                    <i className="icon icon-orders" />
                    <span className="spn">Orders</span>
                  </Link>
                </Menu.Item>
              )}
              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes(
                    "Verifications"
                  ))) && (
                <Menu.Item key="verification">
                  <Link to="/doctor/verification">
                    <i className="icon icon-orders" />
                    <span className="spn">Verifications</span>
                  </Link>
                </Menu.Item>
              )}
              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes("Invoices"))) && (
                <Menu.Item key="orders">
                  <Link to="/doctor/payment">
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="icon"
                    />

                    <span className="spn">Invoices</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes("Credits"))) && (
                <Menu.Item key="credit">
                  <Link to="/doctor/credit">
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      className="icon"
                    />

                    <span className="spn">Credits</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes("Templates"))) && (
                <Menu.Item key="template">
                  <Link to="/doctor/template">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      className="icon"
                    />

                    <span className="spn">Templates</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes("Demographics"))) && (
                <Menu.Item key="demograph">
                  <Link to="/doctor/demograph">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      className="icon"
                    />

                    <span className="spn">Demographics</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "doctor" ||
                (this.props.authR?.user?.role === "doctorNurse" &&
                  this.state.nurse?.permissions?.includes(
                    "Administrator"
                  ))) && (
                <Menu.Item key="orders">
                  <Link to="/doctor/nurse">
                    <FontAwesomeIcon icon={faUserDoctor} className="icon" />

                    <span className="spn">Administrators</span>
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ setting, authR }) => {
  const { pathname } = setting;
  return { pathname, authR };
};
export default connect(mapStateToProps)(SidebarContent);
