import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePrescription,
  faFileInvoiceDollar,
  faReceipt,
  faHandHoldingDollar,
  faUserDoctor,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import axios from "axios";

import config from "../../../config";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  state = {
    nurse: "",
  };

  getAdmin = () => {
    var endpoint =
      this.props.authR?.user?.role == "practiceNurse"
        ? "get-practice/nurse"
        : `by-email/${this.props?.authR?.user?.email}`;
    axios.get(`${config.API_URL}/practice/${endpoint}`).then((response) => {
      console.log("--->", response);
      this.setState({
        nurse: response.data.nurse,
      });
    });
  };

  componentDidMount() {
    this.getAdmin();
  }
  render() {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={"gx-sidebar-notifications "}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              className="mnu"
              mode="inline"
            >
              <Menu.Item key="dashboard">
                <Link to="/practice/dashboard">
                  <i className="icon icon-widgets" />
                  <span className="spn">Dashboard</span>
                </Link>
              </Menu.Item>

              {(this.props.authR?.user?.role === "practice" ||
                (this.props.authR?.user?.role === "practiceNurse" &&
                  this.state.nurse?.permissions?.includes("Orders"))) && (
                <Menu.Item key="orders">
                  <Link to="/practice/orders">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      className="icon"
                    />{" "}
                    <span className="spn">Orders</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "practice" ||
                (this.props.authR?.user?.role === "practiceNurse" &&
                  this.state.nurse?.permissions?.includes(
                    "Verifications"
                  ))) && (
                <Menu.Item key="verification">
                  <Link to="/practice/verification">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      className="icon"
                    />
                    <span className="spn">Verifications</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "practice" ||
                (this.props.authR?.user?.role === "practiceNurse" &&
                  this.state.nurse?.permissions?.includes("Demographics"))) && (
                <Menu.Item key="demograph">
                  <Link to="/practice/demograph">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      className="icon"
                    />

                    <span className="spn">Demographics</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "practice" ||
                (this.props.authR?.user?.role === "practiceNurse" &&
                  this.state.nurse?.permissions?.includes("Invoices"))) && (
                <Menu.Item key="orders">
                  <Link to="/practice/payment">
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="icon"
                    />

                    <span className="spn">Invoices</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "practice" ||
                (this.props.authR?.user?.role === "practiceNurse" &&
                  this.state.nurse?.permissions?.includes("Credits"))) && (
                <Menu.Item key="credit">
                  <Link to="/practice/credit">
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      className="icon"
                    />

                    <span className="spn">Credits</span>
                  </Link>
                </Menu.Item>
              )}

              {(this.props.authR?.user?.role === "practice" ||
                (this.props.authR?.user?.role === "practiceNurse" &&
                  this.state.nurse?.permissions?.includes(
                    "Administrators"
                  ))) && (
                <Menu.Item key="orders">
                  <Link to="/practice/nurse">
                    <FontAwesomeIcon icon={faUserDoctor} className="icon" />

                    <span className="spn">Administrator</span>
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ setting, authR }) => {
  const { pathname } = setting;
  return { pathname, authR };
};
export default connect(mapStateToProps)(SidebarContent);

/*
        <MenuItemGroup key="current" className="gx-menu-group" title={<IntlMessages id="sidebar.current"/>}>
                <Menu.Item key="current/events">
                  <Link to="/user/us/current/events">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.current.events"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="current/calender">
                  <Link to="/user/us/current/calender">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.current.calender"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="profile" className="gx-menu-group" title={<IntlMessages id="sidebar.profile"/>}>
                <Menu.Item key="profile/MyProfile">
                  <Link to="/user/us/profile/MyProfile">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.profile.myprofile"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="profile/EditProfile">
                  <Link to="/user/us/profile/EditProfile">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.profile.edit"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
      */
