import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import {
  logoutUser,
  setCurrentUser,
} from "../../../appRedux/actions/AuthActions";
import { Link } from "react-router-dom";
import configureStore, { history } from "../../../appRedux/store";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../../util/setAuthToken";

export const store = configureStore();

class UserProfile extends Component {
  state = {
    admin: "",
  };

  switchback = () => {
    if (localStorage.AdminToken) {
      // Set auth token header auth
      const token = localStorage.AdminToken;
      setAuthToken(token);
      // Decode token and get user info and exp
      const decoded = jwt_decode(token);
      console.log(decoded);
      // Set user and isAuthenticated
      localStorage.setItem("jwtToken", token);
      //  localStorage.removeItem()

      store.dispatch(setCurrentUser(decoded));
      //   this.props.history.push("/admin/dashboard"); // push user to dashboard when they login
      window.location.href = "/admin/dashboard";
      localStorage.removeItem("AdminToken");
    }
  };

  componentDidMount() {
    if (localStorage.AdminToken) {
      this.setState({ admin: jwt_decode(localStorage.AdminToken) });
      // Set auth token header auth
      //    const token = localStorage.AdminToken;
      //  setAuthToken(token);
      // Decode token and get user info and exp
      //      const decoded = jwt_decode(token);
      //console.log(decoded)
    }
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.logoutUser()}>Logout</li>
        <li>
          <Link style={{ color: "black" }} to="/doctor/config">
            Config
          </Link>
        </li>
        {localStorage.AdminToken && (
          <li onClick={(e) => this.switchback()}>Switch back</li>
        )}
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          {localStorage.AdminToken && (
            <div className="spn">Impersonating Doctor</div>
          )}

          {this.props.auth?.user?.role == "doctorNurse" && (
            <div className="spn">
              {this.props.auth?.user?.name} Impersonating Doctor
            </div>
          )}
          <div className="spn">Logged In As</div>
          {localStorage.AdminToken ? (
            <span className="spn gx-avatar-name">
              {this.state.admin && this.state.admin.name
                ? this.state.admin.name
                : "Admin"}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </span>
          ) : (
            <span className="spn gx-avatar-name">
              {this.props.auth && this.props.auth.isAuthenticated
                ? this.props.auth.user.name
                : "Doctor"}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </span>
          )}
        </Popover>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.authR,
});

//export default UserProfile
export default connect(mapStateToProps, { logoutUser })(UserProfile);
